<template>
  <div id="app">
    <v-app  id="inspire">
      <span class="backroundimage"></span>
        <v-container v-if="allowedToReply === false" fluid fill-height>
          <v-layout align-center justify-center>
            <v-flex xs12 sm8 md7>
              <v-card  class="elevation-12" color="#3fbec7f5">
                <div class="logo">
                  <v-img alt="logo" src="../assets/logo.png"></v-img>
                </div>
                <div>
                  <h3 class="text-center">Please Enter Your Reply Code</h3>
                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-text-field
                        label="Reply Code"
                        name="login"
                        prepend-icon="mdi-email"
                        type="text"
                        autocomplete="off"
                        :rules="[v => !!v || 'Reply Code is required']"
                        v-model="enteredToken"
                      ></v-text-field>
                    </v-form>
                    <div class="danger-alert" v-html="error" />
                    <v-card-text></v-card-text>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn
                      rounded
                      large
                      :loading="loading1"
                      @click="verifyToken"
                      :disabled="loading1 ||!valid"
                      color="primary"
                    >
                      Login
                      <v-icon>mdi-lock-open-variant-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      <v-container v-if="allowedToReply === true" fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md7>
            <v-card  class="elevation-12" >
          <v-row justify="center">
            <h1>Reply Back</h1>
          </v-row>
          <v-col cols="12">
            <v-row>
              <v-text-field
                :readonly="true"
                rounded
                v-model="replyTokenInfo[0].CampersName"
                label="From"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                :readonly="true"
                rounded
                v-model="replyTokenInfo[0].MessageFrom"
                label="To"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col cols="12">
              <v-textarea v-model="message" :rules="[v => !!v || 'Item is required']">
                <template v-slot:label>
                  <div>Messsage</div>
                </template>
              </v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn
                rounded
                class="white"
                :loading="loading2"
                @click="replyMessage"
                :disabled="loading2"
                color="primary"
              >
                Reply
                <v-icon>mdi-email-send</v-icon>
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-refresh</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-row>
          </v-col>
          </v-card>
            </v-flex>
          </v-layout>
        </v-container>

    </v-app>
  </div>
</template>
<script>
// @ is an alias to /src
import messagesService from "../services/Messages";

export default {
  name: "Reply",
  components: {},
  data() {
    return {
      valid: null,
      loading1: false,
      loading2: false,
      allowedToReply: false,
      enteredToken: null,
      message: null,
      replyTokenInfo: [null],
      error: null,
    };
  },
  async mounted() {},
  methods: {
    async verifyToken() {
      if (this.$refs.form.validate())
        try {
          this.loading1 = true;
          const response = await messagesService.verifyReplyToken(
            this.enteredToken
          );
          this.replyTokenInfo = response.data;
          this.loading1 = false;
          this.allowedToReply = true;
        } catch (err) {
          this.error = err.response.data.error;
          this.loading1 = false;
        }
    },
    async replyMessage() {
      try {
        this.loading2 = true;
        await messagesService.newReply({
          ReplyToken: this.replyTokenInfo[0].Token,
          ReplyMessage: this.message,
          From: this.replyTokenInfo[0].CampersName
        });
        this.loading2 = false;
        this.allowedToReply = false;
        this.enteredToken = null
        this.message = null
      } catch (err) {
        alert(err);
        this.loading2 = false;
      }
    }
  }
};
</script>
<style scoped>
/*to be removed */

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  margin: 0 auto;
}
.backroundimage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/login_background.jpg")
    no-repeat center center;
  background-size: cover;
  filter: brightness(50%);
}
</style>